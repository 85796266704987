// @generated by Peggy 4.0.0.
//
// https://peggyjs.org/


function peg$subclass(child, parent) {
  function C() { this.constructor = child; }
  C.prototype = parent.prototype;
  child.prototype = new C();
}

function peg$SyntaxError(message, expected, found, location) {
  var self = Error.call(this, message);
  // istanbul ignore next Check is a necessary evil to support older environments
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(self, peg$SyntaxError.prototype);
  }
  self.expected = expected;
  self.found = found;
  self.location = location;
  self.name = "SyntaxError";
  return self;
}

peg$subclass(peg$SyntaxError, Error);

function peg$padEnd(str, targetLength, padString) {
  padString = padString || " ";
  if (str.length > targetLength) { return str; }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

peg$SyntaxError.prototype.format = function(sources) {
  var str = "Error: " + this.message;
  if (this.location) {
    var src = null;
    var k;
    for (k = 0; k < sources.length; k++) {
      if (sources[k].source === this.location.source) {
        src = sources[k].text.split(/\r\n|\n|\r/g);
        break;
      }
    }
    var s = this.location.start;
    var offset_s = (this.location.source && (typeof this.location.source.offset === "function"))
      ? this.location.source.offset(s)
      : s;
    var loc = this.location.source + ":" + offset_s.line + ":" + offset_s.column;
    if (src) {
      var e = this.location.end;
      var filler = peg$padEnd("", offset_s.line.toString().length, ' ');
      var line = src[s.line - 1];
      var last = s.line === e.line ? e.column : line.length + 1;
      var hatLen = (last - s.column) || 1;
      str += "\n --> " + loc + "\n"
          + filler + " |\n"
          + offset_s.line + " | " + line + "\n"
          + filler + " | " + peg$padEnd("", s.column - 1, ' ')
          + peg$padEnd("", hatLen, "^");
    } else {
      str += "\n at " + loc;
    }
  }
  return str;
};

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    class: function(expectation) {
      var escapedParts = expectation.parts.map(function(part) {
        return Array.isArray(part)
          ? classEscape(part[0]) + "-" + classEscape(part[1])
          : classEscape(part);
      });

      return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
    },

    any: function() {
      return "any character";
    },

    end: function() {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/"/g,  "\\\"")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/\]/g, "\\]")
      .replace(/\^/g, "\\^")
      .replace(/-/g,  "\\-")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = expected.map(describeExpectation);
    var i, j;

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== undefined ? options : {};

  var peg$FAILED = {};
  var peg$source = options.grammarSource;

  var peg$startRuleFunctions = { LPFile: peg$parseLPFile };
  var peg$startRuleFunction = peg$parseLPFile;

  var peg$c0 = "end";
  var peg$c1 = "maximize";
  var peg$c2 = "minimize";
  var peg$c3 = "max";
  var peg$c4 = "min";
  var peg$c5 = ":";
  var peg$c6 = "subject to";
  var peg$c7 = "st";
  var peg$c8 = "s.t.";
  var peg$c9 = "<=";
  var peg$c10 = ">=";
  var peg$c11 = "=";
  var peg$c12 = "=<";
  var peg$c13 = "=>";
  var peg$c14 = "<";
  var peg$c15 = "bounds";
  var peg$c16 = "free";
  var peg$c17 = "-infinity";
  var peg$c18 = "+infinity";
  var peg$c19 = "-inf";
  var peg$c20 = "+inf";
  var peg$c21 = "binary";
  var peg$c22 = "binaries";
  var peg$c23 = "bin";
  var peg$c24 = "generals";
  var peg$c25 = "general";
  var peg$c26 = "gen";
  var peg$c27 = ".";
  var peg$c28 = "e";
  var peg$c29 = "\\";
  var peg$c30 = "\n";

  var peg$r0 = /^[+\-]/;
  var peg$r1 = /^[a-zA-Z!"#$%&'()*+,.\/;?@_`'{|}~]/;
  var peg$r2 = /^[a-zA-Z0-9!"#$%&'()*+,.\/;?@_`'{|}~]/;
  var peg$r3 = /^[0-9]/;
  var peg$r4 = /^[ \t\n\r]/;
  var peg$r5 = /^[^\n]/;

  var peg$e0 = peg$literalExpectation("End", true);
  var peg$e1 = peg$literalExpectation("Maximize", true);
  var peg$e2 = peg$literalExpectation("Minimize", true);
  var peg$e3 = peg$literalExpectation("MAX", true);
  var peg$e4 = peg$literalExpectation("MIN", true);
  var peg$e5 = peg$literalExpectation(":", false);
  var peg$e6 = peg$literalExpectation("Subject To", true);
  var peg$e7 = peg$literalExpectation("ST", true);
  var peg$e8 = peg$literalExpectation("S.T.", true);
  var peg$e9 = peg$literalExpectation("<=", false);
  var peg$e10 = peg$literalExpectation(">=", false);
  var peg$e11 = peg$literalExpectation("=", false);
  var peg$e12 = peg$literalExpectation("=<", false);
  var peg$e13 = peg$literalExpectation("=>", false);
  var peg$e14 = peg$literalExpectation("<", false);
  var peg$e15 = peg$literalExpectation("Bounds", true);
  var peg$e16 = peg$literalExpectation("free", true);
  var peg$e17 = peg$otherExpectation("infinity number");
  var peg$e18 = peg$literalExpectation("-infinity", true);
  var peg$e19 = peg$literalExpectation("+infinity", true);
  var peg$e20 = peg$literalExpectation("-inf", true);
  var peg$e21 = peg$literalExpectation("+inf", true);
  var peg$e22 = peg$literalExpectation("Binary", true);
  var peg$e23 = peg$literalExpectation("Binaries", true);
  var peg$e24 = peg$literalExpectation("Bin", true);
  var peg$e25 = peg$literalExpectation("Generals", true);
  var peg$e26 = peg$literalExpectation("General", true);
  var peg$e27 = peg$literalExpectation("Gen", true);
  var peg$e28 = peg$classExpectation(["+", "-"], false, false);
  var peg$e29 = peg$otherExpectation("variable name");
  var peg$e30 = peg$classExpectation([["a", "z"], ["A", "Z"], "!", "\"", "#", "$", "%", "&", "'", "(", ")", "*", "+", ",", ".", "/", ";", "?", "@", "_", "`", "'", "{", "|", "}", "~"], false, false);
  var peg$e31 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "!", "\"", "#", "$", "%", "&", "'", "(", ")", "*", "+", ",", ".", "/", ";", "?", "@", "_", "`", "'", "{", "|", "}", "~"], false, false);
  var peg$e32 = peg$otherExpectation("signed number");
  var peg$e33 = peg$otherExpectation("number");
  var peg$e34 = peg$classExpectation([["0", "9"]], false, false);
  var peg$e35 = peg$literalExpectation(".", false);
  var peg$e36 = peg$literalExpectation("e", true);
  var peg$e37 = peg$otherExpectation("whitespace or comment");
  var peg$e38 = peg$classExpectation([" ", "\t", "\n", "\r"], false, false);
  var peg$e39 = peg$literalExpectation("\\", false);
  var peg$e40 = peg$classExpectation(["\n"], true, false);
  var peg$e41 = peg$literalExpectation("\n", false);

  var peg$f0 = function(header, constraints, bounds, general, binary) {
    return {
      objective: header,
      constraints: constraints ? constraints : [],
      bounds: bounds ? bounds : [],
      general: general ? general : [],
      binary: binary ? binary : []
    };
  };
  var peg$f1 = function(objectiveType, name, expr) {
    return {
      type: objectiveType.toLowerCase().startsWith('max') ? 'max' : 'min',
      name: name ? name[0] : null,
      expression: expr
    };
  };
  var peg$f2 = function(constraints) {
    return constraints;
  };
  var peg$f3 = function(name, expr, sense, value) {
    return {
      name: name ? name[0] : null,
      expression: expr,
      sense: sense,
      value: value
    };
  };
  var peg$f4 = function() { return text() === '<' ? '<=' : text() === '>' ? '>=' : text(); };
  var peg$f5 = function(bounds) {
    return bounds;
  };
  var peg$f6 = function(variable) { // Handle 'free' variables
      return { variable: variable, range: "free" };
    };
  var peg$f7 = function(lower, variable, upper) { // Full range bounds with support for infinity
      return { variable: variable, lower: lower, upper: upper };
    };
  var peg$f8 = function(variable, upper) { // Upper bound only with support for infinity
      return { variable: variable, upper: upper };
    };
  var peg$f9 = function(lower, variable) { // Lower bound only with support for infinity
      return { variable: variable, lower: lower };
    };
  var peg$f10 = function(infinity) { 
    return infinity.startsWith('-') ? "-infinity" : "+infinity";
    };
  var peg$f11 = function(vars) {
	  return vars.map(v => v[0]); 
  };
  var peg$f12 = function(vars) {
	  return vars.map(v => v[0]); 
  };
  var peg$f13 = function(first, rest) {
      let terms = [first];
      for (let r of rest) {
        let sign = r[1];
        let term = r[3];
        if (sign === '-') {
          term.coefficient = -term.coefficient;
        }
        terms.push(term);
      }
      return terms;
    };
  var peg$f14 = function(sign, term) {
      term.coefficient = (sign === '+' ? 1 : -1) * Math.abs(term.coefficient);
      return term;
    };
  var peg$f15 = function(coefficient, variable) {
      return { coefficient: coefficient, variable: variable };
    };
  var peg$f16 = function(variable) {
      return { coefficient: 1, variable: variable };
    };
  var peg$f17 = function(number) {
      return { coefficient: number, variable: null };
    };
  var peg$f18 = function() {
    return text();
  };
  var peg$f19 = function() {
    return parseFloat(text());
  };
  var peg$f20 = function(number) {
    return parseFloat(text());
  };
  var peg$currPos = options.peg$currPos | 0;
  var peg$savedPos = peg$currPos;
  var peg$posDetailsCache = [{ line: 1, column: 1 }];
  var peg$maxFailPos = peg$currPos;
  var peg$maxFailExpected = options.peg$maxFailExpected || [];
  var peg$silentFails = options.peg$silentFails | 0;

  var peg$result;

  if (options.startRule) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function offset() {
    return peg$savedPos;
  }

  function range() {
    return {
      source: peg$source,
      start: peg$savedPos,
      end: peg$currPos
    };
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos];
    var p;

    if (details) {
      return details;
    } else {
      if (pos >= peg$posDetailsCache.length) {
        p = peg$posDetailsCache.length - 1;
      } else {
        p = pos;
        while (!peg$posDetailsCache[--p]) {}
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos, endPos, offset) {
    var startPosDetails = peg$computePosDetails(startPos);
    var endPosDetails = peg$computePosDetails(endPos);

    var res = {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
    if (offset && peg$source && (typeof peg$source.offset === "function")) {
      res.start = peg$source.offset(res.start);
      res.end = peg$source.offset(res.end);
    }
    return res;
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseLPFile() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseObjective();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      s4 = peg$parseConstraints();
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        s6 = peg$parseBounds();
        if (s6 === peg$FAILED) {
          s6 = null;
        }
        s7 = peg$parse_();
        s8 = peg$parseGeneral();
        if (s8 === peg$FAILED) {
          s8 = null;
        }
        s9 = peg$parse_();
        s10 = peg$parseBinary();
        if (s10 === peg$FAILED) {
          s10 = null;
        }
        s11 = peg$parse_();
        s12 = input.substr(peg$currPos, 3);
        if (s12.toLowerCase() === peg$c0) {
          peg$currPos += 3;
        } else {
          s12 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e0); }
        }
        if (s12 !== peg$FAILED) {
          s13 = peg$parse_();
          peg$savedPos = s0;
          s0 = peg$f0(s2, s4, s6, s8, s10);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseObjective() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 8);
    if (s1.toLowerCase() === peg$c1) {
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e1); }
    }
    if (s1 === peg$FAILED) {
      s1 = input.substr(peg$currPos, 8);
      if (s1.toLowerCase() === peg$c2) {
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e2); }
      }
      if (s1 === peg$FAILED) {
        s1 = input.substr(peg$currPos, 3);
        if (s1.toLowerCase() === peg$c3) {
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e3); }
        }
        if (s1 === peg$FAILED) {
          s1 = input.substr(peg$currPos, 3);
          if (s1.toLowerCase() === peg$c4) {
            peg$currPos += 3;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e4); }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$currPos;
      s4 = peg$parseVariableName();
      if (s4 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s5 = peg$c5;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e5); }
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      s4 = peg$parse_();
      s5 = peg$parseExpression();
      if (s5 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f1(s1, s3, s5);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConstraints() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 10);
    if (s1.toLowerCase() === peg$c6) {
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e6); }
    }
    if (s1 === peg$FAILED) {
      s1 = input.substr(peg$currPos, 2);
      if (s1.toLowerCase() === peg$c7) {
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e7); }
      }
      if (s1 === peg$FAILED) {
        s1 = input.substr(peg$currPos, 4);
        if (s1.toLowerCase() === peg$c8) {
          peg$currPos += 4;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e8); }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = [];
      s4 = peg$parseConstraint();
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseConstraint();
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f2(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConstraint() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseVariableName();
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 58) {
        s3 = peg$c5;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e5); }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$parse_();
    s3 = peg$parseExpression();
    if (s3 !== peg$FAILED) {
      s4 = peg$parse_();
      s5 = peg$parseConstraintSense();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseSignedNumber();
        if (s7 !== peg$FAILED) {
          s8 = peg$parse_();
          peg$savedPos = s0;
          s0 = peg$f3(s1, s3, s5, s7);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConstraintSense() {
    var s0, s1;

    if (input.substr(peg$currPos, 2) === peg$c9) {
      s0 = peg$c9;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e9); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c10) {
        s0 = peg$c10;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e10); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 61) {
          s0 = peg$c11;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e11); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c12) {
            s0 = peg$c12;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e12); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c13) {
              s0 = peg$c13;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e13); }
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 60) {
                s1 = peg$c14;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e14); }
              }
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$f4();
              }
              s0 = s1;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseBounds() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 6);
    if (s1.toLowerCase() === peg$c15) {
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e15); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = [];
      s4 = peg$parseBound();
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseBound();
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f5(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBound() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

    s0 = peg$currPos;
    s1 = peg$parseVariableName();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = input.substr(peg$currPos, 4);
      if (s3.toLowerCase() === peg$c16) {
        peg$currPos += 4;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e16); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        peg$savedPos = s0;
        s0 = peg$f6(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseInfinityNumber();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        if (input.substr(peg$currPos, 2) === peg$c9) {
          s3 = peg$c9;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e9); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseVariableName();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            if (input.substr(peg$currPos, 2) === peg$c9) {
              s7 = peg$c9;
              peg$currPos += 2;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e9); }
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parse_();
              s9 = peg$parseInfinityNumber();
              if (s9 !== peg$FAILED) {
                s10 = peg$parse_();
                peg$savedPos = s0;
                s0 = peg$f7(s1, s5, s9);
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseVariableName();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          if (input.substr(peg$currPos, 2) === peg$c9) {
            s3 = peg$c9;
            peg$currPos += 2;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e9); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseInfinityNumber();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              peg$savedPos = s0;
              s0 = peg$f8(s1, s5);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseInfinityNumber();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (input.substr(peg$currPos, 2) === peg$c9) {
              s3 = peg$c9;
              peg$currPos += 2;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e9); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$parseVariableName();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                peg$savedPos = s0;
                s0 = peg$f9(s1, s5);
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    return s0;
  }

  function peg$parseInfinityNumber() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = input.substr(peg$currPos, 9);
    if (s1.toLowerCase() === peg$c17) {
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e18); }
    }
    if (s1 === peg$FAILED) {
      s1 = input.substr(peg$currPos, 9);
      if (s1.toLowerCase() === peg$c18) {
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e19); }
      }
      if (s1 === peg$FAILED) {
        s1 = input.substr(peg$currPos, 4);
        if (s1.toLowerCase() === peg$c19) {
          peg$currPos += 4;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e20); }
        }
        if (s1 === peg$FAILED) {
          s1 = input.substr(peg$currPos, 4);
          if (s1.toLowerCase() === peg$c20) {
            peg$currPos += 4;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e21); }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f10(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$parseSignedNumber();
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e17); }
    }

    return s0;
  }

  function peg$parseBinaryHeader() {
    var s0;

    s0 = input.substr(peg$currPos, 6);
    if (s0.toLowerCase() === peg$c21) {
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e22); }
    }
    if (s0 === peg$FAILED) {
      s0 = input.substr(peg$currPos, 8);
      if (s0.toLowerCase() === peg$c22) {
        peg$currPos += 8;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e23); }
      }
      if (s0 === peg$FAILED) {
        s0 = input.substr(peg$currPos, 3);
        if (s0.toLowerCase() === peg$c23) {
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e24); }
        }
      }
    }

    return s0;
  }

  function peg$parseGeneralHeader() {
    var s0;

    s0 = input.substr(peg$currPos, 8);
    if (s0.toLowerCase() === peg$c24) {
      peg$currPos += 8;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e25); }
    }
    if (s0 === peg$FAILED) {
      s0 = input.substr(peg$currPos, 7);
      if (s0.toLowerCase() === peg$c25) {
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e26); }
      }
      if (s0 === peg$FAILED) {
        s0 = input.substr(peg$currPos, 3);
        if (s0.toLowerCase() === peg$c26) {
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e27); }
        }
      }
    }

    return s0;
  }

  function peg$parseGeneral() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseGeneralHeader();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = [];
      s4 = peg$currPos;
      s5 = peg$parseVariableName();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s5 = [s5, s6];
        s4 = s5;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$parseVariableName();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f11(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBinary() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseBinaryHeader();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = [];
      s4 = peg$currPos;
      s5 = peg$parseVariableName();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s5 = [s5, s6];
        s4 = s5;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$parseVariableName();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f12(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseTermWithSign();
    if (s1 === peg$FAILED) {
      s1 = peg$parseTerm();
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = input.charAt(peg$currPos);
      if (peg$r0.test(s5)) {
        peg$currPos++;
      } else {
        s5 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e28); }
      }
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseTermWithSign();
        if (s7 === peg$FAILED) {
          s7 = peg$parseTerm();
        }
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = input.charAt(peg$currPos);
        if (peg$r0.test(s5)) {
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e28); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseTermWithSign();
          if (s7 === peg$FAILED) {
            s7 = peg$parseTerm();
          }
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f13(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTermWithSign() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = input.charAt(peg$currPos);
    if (peg$r0.test(s1)) {
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e28); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseTerm();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f14(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTerm() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseNumber();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseVariableName();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f15(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseVariableName();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f16(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseNumber();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$f17(s1);
        }
        s0 = s1;
      }
    }

    return s0;
  }

  function peg$parseVariableName() {
    var s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseBinaryHeader();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parseGeneralHeader();
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = input.substr(peg$currPos, 3);
        if (s4.toLowerCase() === peg$c0) {
          peg$currPos += 3;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e0); }
        }
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = undefined;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = input.charAt(peg$currPos);
          if (peg$r1.test(s4)) {
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e30); }
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = input.charAt(peg$currPos);
            if (peg$r2.test(s6)) {
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e31); }
            }
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = input.charAt(peg$currPos);
              if (peg$r2.test(s6)) {
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$e31); }
              }
            }
            peg$savedPos = s0;
            s0 = peg$f18();
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e29); }
    }

    return s0;
  }

  function peg$parseSignedNumber() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = input.charAt(peg$currPos);
    if (peg$r0.test(s1)) {
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e28); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$parseNumber();
    if (s2 !== peg$FAILED) {
      peg$savedPos = s0;
      s0 = peg$f19();
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e32); }
    }

    return s0;
  }

  function peg$parseNumber() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r3.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e34); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = input.charAt(peg$currPos);
        if (peg$r3.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e34); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 46) {
        s3 = peg$c27;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e35); }
      }
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = input.charAt(peg$currPos);
        if (peg$r3.test(s5)) {
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e34); }
        }
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = input.charAt(peg$currPos);
            if (peg$r3.test(s5)) {
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e34); }
            }
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      s3 = peg$currPos;
      s4 = input.charAt(peg$currPos);
      if (s4.toLowerCase() === peg$c28) {
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e36); }
      }
      if (s4 !== peg$FAILED) {
        s5 = input.charAt(peg$currPos);
        if (peg$r0.test(s5)) {
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e28); }
        }
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = [];
        s7 = input.charAt(peg$currPos);
        if (peg$r3.test(s7)) {
          peg$currPos++;
        } else {
          s7 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e34); }
        }
        if (s7 !== peg$FAILED) {
          while (s7 !== peg$FAILED) {
            s6.push(s7);
            s7 = input.charAt(peg$currPos);
            if (peg$r3.test(s7)) {
              peg$currPos++;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e34); }
            }
          }
        } else {
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s4 = [s4, s5, s6];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f20(s1);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e33); }
    }

    return s0;
  }

  function peg$parse_() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = input.charAt(peg$currPos);
    if (peg$r4.test(s2)) {
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e38); }
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = input.charAt(peg$currPos);
      if (peg$r4.test(s2)) {
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e38); }
      }
    }
    s2 = [];
    s3 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 92) {
      s4 = peg$c29;
      peg$currPos++;
    } else {
      s4 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e39); }
    }
    if (s4 !== peg$FAILED) {
      s5 = [];
      s6 = input.charAt(peg$currPos);
      if (peg$r5.test(s6)) {
        peg$currPos++;
      } else {
        s6 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e40); }
      }
      while (s6 !== peg$FAILED) {
        s5.push(s6);
        s6 = input.charAt(peg$currPos);
        if (peg$r5.test(s6)) {
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e40); }
        }
      }
      if (input.charCodeAt(peg$currPos) === 10) {
        s6 = peg$c30;
        peg$currPos++;
      } else {
        s6 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e41); }
      }
      if (s6 !== peg$FAILED) {
        s7 = [];
        s8 = input.charAt(peg$currPos);
        if (peg$r4.test(s8)) {
          peg$currPos++;
        } else {
          s8 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e38); }
        }
        while (s8 !== peg$FAILED) {
          s7.push(s8);
          s8 = input.charAt(peg$currPos);
          if (peg$r4.test(s8)) {
            peg$currPos++;
          } else {
            s8 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e38); }
          }
        }
        s4 = [s4, s5, s6, s7];
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    while (s3 !== peg$FAILED) {
      s2.push(s3);
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s4 = peg$c29;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e39); }
      }
      if (s4 !== peg$FAILED) {
        s5 = [];
        s6 = input.charAt(peg$currPos);
        if (peg$r5.test(s6)) {
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e40); }
        }
        while (s6 !== peg$FAILED) {
          s5.push(s6);
          s6 = input.charAt(peg$currPos);
          if (peg$r5.test(s6)) {
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e40); }
          }
        }
        if (input.charCodeAt(peg$currPos) === 10) {
          s6 = peg$c30;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$e41); }
        }
        if (s6 !== peg$FAILED) {
          s7 = [];
          s8 = input.charAt(peg$currPos);
          if (peg$r4.test(s8)) {
            peg$currPos++;
          } else {
            s8 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$e38); }
          }
          while (s8 !== peg$FAILED) {
            s7.push(s8);
            s8 = input.charAt(peg$currPos);
            if (peg$r4.test(s8)) {
              peg$currPos++;
            } else {
              s8 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$e38); }
            }
          }
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    }
    s1 = [s1, s2];
    s0 = s1;
    peg$silentFails--;
    s1 = peg$FAILED;
    if (peg$silentFails === 0) { peg$fail(peg$e37); }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (options.peg$library) {
    return /** @type {any} */ ({
      peg$result,
      peg$currPos,
      peg$FAILED,
      peg$maxFailExpected,
      peg$maxFailPos
    });
  }
  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

const peg$allowedStartRules = [
  "LPFile"
];

export {
  peg$allowedStartRules as StartRules,
  peg$SyntaxError as SyntaxError,
  peg$parse as parse
};
